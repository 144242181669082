.app {
  text-align: center;
}

.app-logo {
  height: 30vmin;
  pointer-events: none;
}

.app-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  color: black;
}

.app-link {
  color: purple;
  width: calc(100% - 2rem);
  line-height: 2.8rem;
}

p {
  padding: 0 1rem;
}

h1,
h3 {
  text-align: center;
}

.container {
  padding: 0 1rem;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666;
}

.btn.btn-primary {
  font-family: quicksand;
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  background-color: #e36f1e;
  color: white;
  border: 0px;
}

.btn.btn-primary:disabled {
  font-family: quicksand;
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  background-color: #e36f1e;
  color: white;
  border: 0px;
}

.btn.btn-danger {
  font-family: quicksand;
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  background-color: #da1e28;
  color: white;
  border: 0px;
}

.btn.btn-primary.w-100 {
  font-family: quicksand;
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  background-color: #e36f1e;
  color: white;
  border: 0px;
  width: 100%;
}

.btn.btn-lg.form-control {
  font-family: quicksand;
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  background-color: #e36f1e;
  color: white;
  border: 0px;
}
.btn.btn-lg.form-control.float {
  font-family: quicksand;
  font-size: 20px;
  font-weight: bold;
  flex: 1;
  background-color: #e36f1e;
  color: white;
  border: 0px;
  float: right;
}

.textStyle {
  color: #02294a;
  font-family: "Fira Sans", sans-serif;
  font-family: "quicksand", sans-serif;
}

.navbar-brand.no-arrow-icon {
  margin: 0;
  padding: 0;
  width: 102px;
  height: 30px;
  font-family: "Quicksand";
  font-style: normal;
  background: white;
  z-index: 1;
  font-weight: 700;
  align-items: center;
  text-align: center;
  color: #fdb515 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar-brand.arrow-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  background: white;
  z-index: 1;
  height: 30px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fdb515 !important;
  order: 0;
  flex-grow: 0;
}

.navbar-brand-title {
  color: inherit;
  margin-right: 110px;
  user-select: none;
  text-decoration: none !important;
  background-color: white;
}

.navbar-brand-title:hover {
  color: inherit;
  user-select: none;
  text-decoration: none !important;
  background-color: white;
}

@media only screen and (min-width: 600px) {
  .navbar-brand-title {
    margin-right: 0;
    user-select: none;
    text-decoration: none !important;
    background-color: white;
  }
}

.toast__success-body {
  font-family: "Fira Sans";
  font-size: 20px;
  color: white !important;
}

.form-check-input {
  accent-color: #e36f1e;
}

.sp-action {
  color: #007bff;
  cursor: pointer;
}

.group-multi-select {
  flex: 10 1 0%;
  /* padding: 5px; */
  font-family: "Fira Sans";
  font-size: 20px;
  height: 50px;
  border-radius: var(--bs-border-radius) !important;
}

/* css for arrow indicator separator of react-select component */
.css-1u9des2-indicatorSeparator {
  background-color: #ffffff !important;
}

/* css for arrow indicator of react-select component */
.css-1xc3v61-indicatorContainer {
  color: rgba(0, 0, 0, 0.514) !important;
}

/* css for place holder text of react-select component */
.css-1jqq78o-placeholder {
  color: var(--bs-body-color) !important;
  flex: 10 1 0% !important;
  font-family: "Fira Sans" !important;
  font-size: 20px !important;
  height: 46px !important;
  padding-top: 8px;
  margin-left: 0;
}
/* css for when component is selected */
.c55-13cymnt-control {
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
}

.selected-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}
