* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Fira Sans", sans-serif;
  font-family: "quicksand", sans-serif;
  /*prevent text from being selected and highlighted*/
  user-select: none; /* Standard syntax */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-select: none; /* Safari */
}

.active-tab {
  background-color: #e36f1e !important;
  color: #fff !important;
}

.tab-div-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.previous-meeting-tabs {
  color: #e36f1e;
  font-family: "quicksand";
  font-weight: bolder;
  font-size: 18px;
  text-align: center;
  padding-bottom: 0.5em;
  margin: 34px 0 0 0;
}
a {
  color: #333;
  text-decoration: none;
}
.float-right {
  float: right !important;
}
.dots-loader span:before {
  animation: dots 2s linear infinite;
  content: "";
}

@keyframes dots {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  90%,
  100% {
    content: "";
  }
}
